
import { defineComponent, ref } from 'vue';
import Sign from './_components/sign/sign.vue';

export default defineComponent({
  name: 'Login',
  components: {
    Sign,
  },
  setup () {
    const showTopContact = ref<boolean>(false)
    function changeTopContact () {
      showTopContact.value = !showTopContact.value
    }
    const showCmcmCode = ref<boolean>(false)
    function changeCmcmCode () {
      showCmcmCode.value = !showCmcmCode.value
    }
    return {
      showTopContact,
      changeTopContact,
      showCmcmCode,
      changeCmcmCode
    }
  }
});
