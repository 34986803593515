import { Injectable } from '@tanbo/vue-di-plugin'

import { HttpService } from '@/services/http.service'
import { UserService } from '@/services/user.service'
import { SoftService, ChannelRequestDataTY } from '@/services/soft.service'

export interface LoginRequestParams {
  /** 登录用户名 */
  username: string
  /** 登录密码 */
  password: string
  /** 登录验证码 */
  check_code: string

  usessionid: string
}

export interface LoginRequestDataNextUrl {
  /** 登录跳转 */
  next: string
}

export interface LoginResponseData {
  /** 接口返回数据 */
  token: string
  next: string
}

export interface LoginResponse {
  /** 接口返回数据 */
  code: number
  data: LoginResponseData
  msg: string
}

@Injectable()
export class LoginService {
  constructor (private http: HttpService,
               private user: UserService,
               private soft: SoftService) {}

  doLogin (params: LoginRequestParams) {
    return this.http.loginPost<LoginResponse>('/accounts/login.json', params).then(response => {
      this.user.updateUserinfo({
        username: params.username
      })
      return response
    })
  }

  ssionId () {
    return this.http.post('/accounts/usessionid.json', {}).then(response => {
      return response
    })
  }

  doGetChannelSoft () {
    return this.http.post<ChannelRequestDataTY>('/get_channel_soft_infos.json', {}).then((response) => {
      this.soft.channelSoftInfos.next(response.data)
      return response
    })
  }
}
