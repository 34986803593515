
import { defineComponent, ref, reactive, onBeforeMount } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import router from '@/router'

import { LoginRequestParams,  LoginService } from './sign.service'
import { SoftService } from '@/services/soft.service'
import { LocalStorageService } from '@/services/local-storage.service'

import { ElMessage } from 'element-plus'
import { getApiBaseUrl } from '@/common/env/app-url'

export default defineComponent({
  name: 'Sign',
  props: {
    msg: String,
  },

  setup () {
    const injector = useReflectiveInjector([LoginService])
    const service = injector.get(LoginService)
    const softService = injector.get(SoftService)
    const localStorageService = injector.get(LocalStorageService);

    const codeUrl = ref<string>('')
    // 请求ssionid
    onBeforeMount(() => {
      getSsionId()
    })
    function getSsionId () {
      service.ssionId().then((response) => {
        codeUrl.value = `${getApiBaseUrl()}/accounts/check_code/?usessionid=${response.data.usessionid}`
        localStorageService.setUserUsessionid(response.data.usessionid)
      }).catch((err) => {
        ElMessage.error(`请求失败,${err.msg}`)
      })
    }
    function ChangeCode () {
      getSsionId()
    }

    const passwordEye = ref<boolean>(false)
    const passwordType = ref<string>('password')
    function changeEye () {
      passwordEye.value = !passwordEye.value
      passwordType.value = passwordEye.value ? 'text' : 'password'
    }

    const userNameLocalStr = localStorageService.getUserName() || {}
    const loginQueryParams = reactive<LoginRequestParams>({
      username: userNameLocalStr.username || '',
      password: '',
      check_code: '',
      usessionid: ''
    })
    const autoLogin = ref<boolean>(false)
    function changeAutoLogin () {
      autoLogin.value = !autoLogin.value
      if (autoLogin.value) {
        localStorageService.setUserName({username: loginQueryParams.username})
      }
    }

    function handleLogin () {
      if (!loginQueryParams.username) {
        ElMessage.warning({
          message: '请输入正确的账号',
          type: 'warning',
        })
        return
      }
      if (!loginQueryParams.password) {
        ElMessage.warning({
          message: '请输入正确的密码',
          type: 'warning',
        })
        return
      }
      if (!loginQueryParams.check_code) {
        ElMessage.warning({
          message: '请输入正确的验证码',
          type: 'warning',
        })
        return
      }
      loginQueryParams.usessionid = localStorageService.getUserUsessionid()
      service.doLogin(loginQueryParams).then((response) => {
        localStorageService.setUserToken(response.data.token)
        softService.updateChannelSoftInfos()
        router.replace('/checkData')
      }).catch((err) => {
        ElMessage.error(`请求失败,${err.msg}`)
      })
    }

    return {
      codeUrl,
      ChangeCode,
      passwordEye,
      passwordType,
      changeEye,
      autoLogin,
      changeAutoLogin,
      loginQueryParams,
      handleLogin
    }
  }
})
